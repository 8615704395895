<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card>
            <v-card-title class="title">
              <!-- <v-icon>line_style</v-icon>  -->
              &nbsp; Mapping Structure
              <v-spacer></v-spacer>
              <v-card outlined>
                <transition
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <add-button
                    permission="account-mapping-create"
                    @action="addMapping"
                    >New mapping
                  </add-button>
                </transition>
              </v-card>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :server-items-length="form.items.data.length"
            :loading="form.loading"
            :footer-props="footerProps"
          >
            <!-- <template slot="items" slot-scope="props"> -->
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td style="font-weight: bold" class="text-xs-left">
                  {{ item.title }}
                </td>
                <td class="text-xs-left">
                  {{ item.income_ledger_name }}
                </td>
                <td class="text-xs-left">{{ item.asset_ledger_name }}</td>
                <td class="text-xs-center" v-if="item.is_verify == 0">
                  <v-btn
                    small
                    icon
                    color="green"
                    class="pa-0 ma-1 white--text"
                    slot="activator"
                    @click="verify(item.id)"
                  >
                    <v-icon small>check_circle</v-icon>
                  </v-btn>
                  <edit-button
                    permission="account-mapping-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="account-mapping-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
                <td class="text-xs-center" v-else>
                  <!-- <v-btn small icon color="primary" class="pa-0 ma-1 white--text" slot="activator"> -->
                  <v-icon small>check_circle</v-icon>
                  <!-- </v-btn> -->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-form
              ref="form"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
              lazy-validation
            >
              <v-layout style="margin-top: 20px" row wrap>
                <v-flex xs3 sm3 style="margin-right: 10px">
                  <v-select
                    outlined
                    dense
                    :loading="fetchingGrade"
                    :items="fee_heads"
                    class="pa-0"
                    label="Fee Head"
                    v-model="form.fee_head_id"
                  />
                </v-flex>

                <v-flex xs4 sm4 style="margin-right: 10px">
                  <v-select
                    outlined
                    dense
                    @change="
                      selectedFeeHead.text === 'Cheque'
                        ? (form.asset_ledger_head_id = '')
                        : null
                    "
                    :loading="fetchingGrade"
                    :items="incomeLedgerHeads"
                    class="pa-0"
                    label="Income Ledger Head"
                    v-model="form.income_ledger_head_id"
                  />
                </v-flex>
                <v-flex xs4 sm4>
                  <v-select
                    outlined
                    dense
                    @change="
                      selectedFeeHead.text === 'Cheque'
                        ? (form.income_ledger_head_id = '')
                        : null
                    "
                    :loading="fetchingGrade"
                    :items="assetsLedgerHeads"
                    class="pa-0"
                    label="Assets Ledger Head"
                    v-model="form.asset_ledger_head_id"
                  />
                </v-flex>

                <v-flex xs10>
                  <div style="color: red; font-weight: bold">
                    <strong>
                      Please be cautious when mapping. This will directly impact
                      on Account Sync.
                    </strong>
                  </div>
                </v-flex>

                <v-flex xs6 sm6> </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="verifyDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >Are you sure you want to Verify this mapping?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="verifyDialog = false"
              >No</v-btn
            >
            <v-btn color="warning" outlined small @click="verifySubmit"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { getPropertyFromArrayObject } from '../../../library/helpers';

export default {
  data: () => ({
    form: new Form(
      {
        fee_head_id: '',
        asset_ledger_head_id: '',
        income_ledger_head_id: '',
      },
      '/api/map-fee-head'
    ),
    search: null,
    fetchingGrade: false,
    rowsPerPageItems: [10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 25,
    },
    headers: [
      { text: "#", align: "left", value: "id", width: 50, sortable: false },
      {
        text: "Fee Head",
        align: "left",
        value: "title",
        width: 350,
        sortable: false,
      },
      {
        text: "Income Ledger Head",
        align: "left",
        value: "income_ledger_head_id",
        width: 250,
        sortable: false,
      },
      {
        text: 'Asset Ledger Head',
        align: 'left',
        value: 'asset_ledger_head_id',
        sortable: false,
        width: 320,
      },
      {
        text: 'Action',
        align: 'center',
        value: 'action',
        sortable: false,
        width: 30,
      },
    ],
    fee_heads: [],
    ledgerHeads: [],
    incomeLedgerHeads: [],
    assetsLedgerHeads: [],
    verifyDialog: false,
    id: "",
    disabledHead: "",
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
  }),

  computed: {
    ...mapState(['batch']),

    selectAllMonths() {
      return this.form.fee_month.length === this.fee_month.length;
    },
    selectFewMonths() {
      return this.form.fee_month.length > 0 && !this.selectAllMonths;
    },
    icon() {
      if (this.selectAllMonths) return 'check_box';
      if (this.selectFewMonths) return 'indeterminate_check_box';
      return 'add_box';
    },

    selectedFeeHead() {
      let selectedHead = {};
      this.fee_heads.map((item) => {
        if (item.text === 'Cheque' && item.value === this.form.fee_head_id) {
          selectedHead = item;
        }
      });
      return selectedHead;
    },
  },

  mounted() {
    this.getFeeHead();
    this.getLedgerHead();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    'form.fee_head_id'() {
      this.form.asset_ledger_head_id = '';
      this.form.income_ledger_head_id = '';
    },
    // "form.asset_ledger_head_id"(value) {
    //   console.log(value, "here");
    //   if (this.selectedFeeHead.text === "Cheque") {
    //     this.form.income_ledger_head_id = "";
    //   }
    // },

    // "form.income_ledger_head_id"(value) {
    //   console.log(value, "...");
    //   if (this.selectedFeeHead.text === "Cheque") {
    //     this.form.asset_ledger_head_id = "";
    //   }
    // },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&')
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      this.form.store();
    },
    getLedgerHead() {
      this.$rest
        .get('api/ledger-head/income-assets?rowsPerPage=100')
        .then(({ data }) => {
          // console.log(data);
          this.incomeLedgerHeads = data.income.map((item) => {
            return { value: item.id, text: item.name };
          });
          this.assetsLedgerHeads = data.assets.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getFeeHead() {
      this.$rest
        .get('/api/fee-head?rowsPerPage=100&withDefaultHeads=true')
        .then(({ data }) => {
          // console.log(data);
          this.fee_heads = data.data.map((item) => {
            return { value: item.id, text: item.title };
          });
        });
    },
    verify(id) {
      console.log(id);
      this.verifyDialog = true;
      this.id = id;
    },
    verifySubmit() {
      this.$rest
        .post('/api/map-fee-head/verify', {
          mapId: this.id,
        })
        .then(({ data }) => {
          this.$events.fire('notification', {
            message: data.message,
            status: 'success',
          });
          this.verifyDialog = false;
          this.get();
        })
        .catch((e) => {
          this.verifyDialog = false;
          this.$events.fire('notification', {
            message: data.message,
            status: 'error',
          });
        });
    },
    addMapping() {
      this.form.dialog = true;

      let feeHeads = getPropertyFromArrayObject(
        'fee_head_id',
        this.form.items.data
      );
      let assetsHeads = getPropertyFromArrayObject(
        'asset_ledger_head_id',
        this.form.items.data
      );
      let incomeHeads = getPropertyFromArrayObject(
        'income_ledger_head_id',
        this.form.items.data
      );

      this.fee_heads = this.fee_heads.diff(feeHeads, 'value');
      this.assetsLedgerHeads = this.assetsLedgerHeads.diff(
        assetsHeads,
        'value'
      );
      this.incomeLedgerHeads = this.incomeLedgerHeads.diff(
        incomeHeads,
        'value'
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
